import Web3 from "web3";

import ValidatorContractJson from '../../data/contracts/abis/ValidatorContract.json';
import UserContractJson from '../../data/contracts/abis/UserContract.json';
import SupporterContractJson from '../../data/contracts/abis/SupporterContract.json';
import ResearcherPoolContractJson from '../../data/contracts/abis/ResearcherPool.json';
import ResearcherContractJson from '../../data/contracts/abis/ResearcherContract.json';
import RcTokenIcoContractJson from '../../data/contracts/abis/RcTokenIco.json';
import RcTokenContractJson from '../../data/contracts/abis/RcToken.json';
import ProducerPoolContractJson from '../../data/contracts/abis/ProducerPool.json';
import InspectorPoolContractJson from '../../data/contracts/abis/InspectorPool.json';
import DevelopersPoolContractJson from '../../data/contracts/abis/DeveloperPool.json';
import ActivistContractJson from '../../data/contracts/abis/ActivistContract.json';
import ActivistPoolContractJson from '../../data/contracts/abis/ActvistPool.json';
import SintropContractJson from '../../data/contracts/abis/Sintrop.json';
import ProducerContractJson from '../../data/contracts/abis/ProducerContract.json';
import InspectorContractJson from '../../data/contracts/abis/InspectorContract.json';
import DeveloperContractJson from '../../data/contracts/abis/DeveloperContract.json';
import ValidatorPoolContractJson from '../../data/contracts/abis/ValidatorPool.json';
import CategoryContractJson from '../../data/contracts/abis/CategoryContract.json';
import InvitationContractJson from '../../data/contracts/abis/InvitationContract.json';
import ContributorContractJson from '../../data/contracts/abis/ContributorContract.json';
import ContributorPoolContractJson from '../../data/contracts/abis/ContributorPool.json';

export const validatorContractAddress = '0x648291fb228cdB775123f4B2a5BC896A475457C8';
export const validatorPoolContractAddress = '0xB8E80394b341c29f6dE327CEFb24797bC1BFe40E';
export const userContractAddress = '0x8bE0B014B7Fd2d3F698874C0CCc4EE2684fD6a25';
export const supporterContractAddress = '0x6175a94A5848D793308e844DfAe37ee89313Cc8e';
export const supporterPoolAddress = '0xed0D578fa387d61aa33E0e9762Dda62355B8CfD5';
export const researcherPoolContractAddress = '0xFDa3a4fAd7BDd910897D8E7422475846a53d5A74';
export const researcherContractAddress = '0xB2CE33276Ae84802b91C4E0F51b930f3a5e44F56';
export const rcTokenIconAddress = '0x1e1cc60a91380c81ecabfbd497c72a7f134f39af';
export const RcTokenContractAddress = '0xA2628da7B5C8B9921E52402438c320e03d191275';
export const producerPoolContractAddress = '0xbaeD2F5C1e389C9909C6d480E096a6C368711C3E';
export const producerContractAddress = '0xE851bF9778d1052696adb90d27392bAcc18dd497';
export const inspectorPoolContractAddress = '0xe81754FbCE05beeE07f8742e1f78487Fb6F26059';
export const inspectorContractAddress = '0x25dcCca15B1211969021F642FC3A746B965E67C9';
export const developersPoolContractAddress = '0x2Cd832AC15EEE83b90ab91c87eC0e467eE315827';
export const developerContractAddress = '0x59900BdCa243242A9666508a52B9A81489aAb459';
export const activistContractAddress = '0xEc12E90A5F173c5d7235e186d02f3ad041B30639';
export const activistPoolContractAddress = '0x0Fbbc578c6F48BA54E5e8A8d45DEB85482ADEa44';
export const sintropContractAddress = '0x62b93FC1CBFFcb6ae6Ffe80D0e022E96aC97760a';
export const categoryContractAddress = '0x8D3157abE0b340e45364CBA8eE8f84AD202CFD7e';
export const invitationContractAddress = '0x206BC8DB206157f639D25315b2D968454F3d725A';
export const contributorContractAddress = '0x52ECDb5E3055890a04e76259971D8Cb2e0c92C71';
export const contributorPoolContractAddress = '0x978a9C4e5670C3C91E6a180448E88a41E8Dc1212';

const provider = window.ethereum ? window.ethereum : process.env.REACT_APP_CHAIN_RPC;
export const web3 = new Web3(provider);

export const ValidatorContract = new web3.eth.Contract(ValidatorContractJson, validatorContractAddress);
export const UserContract = new web3.eth.Contract(UserContractJson, userContractAddress);
export const SupporterContract = new web3.eth.Contract(SupporterContractJson, supporterContractAddress);
export const ResearcherPoolContract = new web3.eth.Contract(ResearcherPoolContractJson, researcherPoolContractAddress);
export const ResearcherContract = new web3.eth.Contract(ResearcherContractJson, researcherContractAddress);
export const RcTokenIcoContract = new web3.eth.Contract(RcTokenIcoContractJson, rcTokenIconAddress);
export const RcTokenContract = new web3.eth.Contract(RcTokenContractJson, RcTokenContractAddress);
export const ProducerPoolContract = new web3.eth.Contract(ProducerPoolContractJson, producerPoolContractAddress);
export const InspectorPoolContract = new web3.eth.Contract(InspectorPoolContractJson, inspectorPoolContractAddress);
export const DevelopersPoolContract = new web3.eth.Contract(DevelopersPoolContractJson, developersPoolContractAddress);
export const ActivistContract = new web3.eth.Contract(ActivistContractJson, activistContractAddress);
export const ActivistPoolContract = new web3.eth.Contract(ActivistPoolContractJson, activistPoolContractAddress);
export const SintropContract = new web3.eth.Contract(SintropContractJson, sintropContractAddress);
export const ProducerContract = new web3.eth.Contract(ProducerContractJson, producerContractAddress);
export const InspectorContract = new web3.eth.Contract(InspectorContractJson, inspectorContractAddress);
export const DeveloperContract = new web3.eth.Contract(DeveloperContractJson, developerContractAddress);
export const ValidatorPoolContract = new web3.eth.Contract(ValidatorPoolContractJson, validatorPoolContractAddress);
export const CategoryContract = new web3.eth.Contract(CategoryContractJson, categoryContractAddress);
export const InvitationContract = new web3.eth.Contract(InvitationContractJson, invitationContractAddress);
export const ContributorContract = new web3.eth.Contract(ContributorContractJson, contributorContractAddress);
export const ContributorPoolContract = new web3.eth.Contract(ContributorPoolContractJson, contributorPoolContractAddress);